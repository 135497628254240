import styled from 'styled-components'
import { Box } from '@mui/material'

export const TabSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '&& .MuiTabs-root': {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 48,
    marginBottom: 64,
  },
  '&& .MuiTab-root': {
    background: 'transparent',
    color: theme.palette.primary.main,
    transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
    borderRadius: 48,
    flex: '1 1 0',
    fontSize: 18,
    minWidth: 140,
    minHeight: 54,
    '&:before, &:after': {
      display: 'none',
    },
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  '&& .MuiTabPanel-root': {
    padding: 0,
    maxWidth: '100%',
  },
}))

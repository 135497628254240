import * as React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/Carousel'
import Cta from '../components/Cta'
import PlansSouthAsia from '../components/PlansSouthAsia'
import PricingTable from '../components/PricingTable'
import Testimonials from '../components/Testimonials'
import TrustedBy from '../components/TrustedBy'
import { Container, Stack } from '@mui/material'

// Content
import { pricingContent } from '../content/pricing-south-asia'
import { logoSlideContent } from '../content/logoSlide'
import PaymentMethods from '../components/PaymentMethods'

const IndexPage = () => {
  const [value, setValue] = React.useState('1')

  return (
    <Layout headerWhite={true}>
      <PlansSouthAsia value={value} />
      <PaymentMethods content={pricingContent.paymentMethods} />
      <PricingTable content={pricingContent.pricingTable} value={value} />
      <Testimonials />
      <Stack direction='column' alignItems='center' spacing={2}>
        <TrustedBy mode={'dark'} disposition={'row'} text={'BUSINESSES'} show={false} />
        <Carousel className='mt-s-28' content={logoSlideContent('big', 'default', true).carousel} />
      </Stack>
      <Container disableGutters maxWidth='xl' sx={{ textAlign: 'center', my: { xs: 6, md: 8 } }}>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          spacing={3}
          sx={{
            '& > section': {
              width: '100%',
            },
          }}
        >
          <Cta content={pricingContent.cta1} />
          <Cta content={pricingContent.cta2} />
        </Stack>
      </Container>
    </Layout>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='Pricing'
    description='Start Flexible Plans: Get plans for every stage of your business growth. Secure payment with a debit card, credit card, PayPal, Alipay and bank transfers.'
    pathname={location.pathname}
  />
)

export default IndexPage

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

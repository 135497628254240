// Base
import * as React from 'react'
import Parser from 'html-react-parser'

// Components
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import CtaButton from '../CtaButton'

// Icons
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const Plan = ({ content, mode }) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Paper
      variant='outlined'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        textAlign: 'left',
        p: '22px',
        pt: 11,
        borderWidth: content?.highlight ? '2px' : '1px',
        borderColor: content?.highlight
          ? 'primary.main'
          : content?.borderHighlight
          ? 'yellow.main'
          : mode === 'dark'
          ? 'bluishGrey.nine'
          : 'bluishGrey.three',
        backgroundColor: content?.highlight
          ? mode === 'dark'
            ? '#1D2947'
            : 'silkyGreen.main'
          : mode === 'dark'
          ? 'inherit'
          : 'bluishGrey.one',
      }}
    >
      {content?.titlebar && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: content?.highlight ? 'primary.main' : 'yellow.main',
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            px: 2,
            minHeight: 70,
          }}
        >
          <Typography
            variant='body2'
            sx={{
              color: content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
              display: 'flex',
            }}
          >
            {content.titlebar.icon && (
              <SvgIcon
                inheritViewBox
                component={content.titlebar.icon}
                sx={{ mr: 1, fontSize: 20, color: content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten' }}
              />
            )}

            {Parser(content.titlebar.text)}
          </Typography>
        </Box>
      )}
      {content?.title && (
        <Typography
          variant='h3'
          sx={{
            color: mode === 'dark' ? 'bluishGrey.two' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
            mb: 3,
            display: 'flex',
          }}
        >
          {content?.icon && <SvgIcon inheritViewBox component={content.icon} sx={{ mr: 1, fontSize: 32 }} />}
          {content.title}
        </Typography>
      )}
      {content?.description && (
        <Typography
          variant='body2'
          sx={{
            color: mode === 'dark' ? 'bluishGrey.seven' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
            minHeight: 76,
          }}
        >
          {content.description}
        </Typography>
      )}
      {content?.price && (
        <Stack
          direction='row'
          alignItems='baseline'
          sx={{
            color: mode === 'dark' ? 'bluishGrey.two' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
            my: 2,
          }}
        >
          {content?.oldPrice && (
            <Typography
              variant='h3'
              component='span'
              sx={{ '&&': { color: 'rose.dark', textDecoration: 'line-through', mr: 2 } }}
            >
              €{content.oldPrice}
            </Typography>
          )}
          {content.title === 'Business' ? (
            <Typography
              variant='h3'
              component='span'
              sx={{ fontSize: { xs: 28, sm: 32 }, lineHeight: { xs: '32px', sm: '52px' } }}
            >
              {content.price}
            </Typography>
          ) : (
            <>
              <Typography variant='h3' component='span'>
                €
              </Typography>
              <Typography variant='h2' component='span' sx={{ fontSize: { xs: 36, sm: 42 } }}>
                {content.price}
              </Typography>
              {content.title !== 'Free' && (
                <Typography
                  variant='body1'
                  component='span'
                  sx={{
                    color:
                      mode === 'dark' ? 'bluishGrey.seven' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
                  }}
                >
                  /year
                </Typography>
              )}
            </>
          )}
        </Stack>
      )}
      <CtaButton
        content={{
          text: 'Join now',
          href: 'SIGNUP',
          size: 'small',
          fullWidth: true,
          variant: 'contained',
          ...content?.button,
        }}
      />
      {content?.list && (
        <>
          <Divider
            sx={{
              borderColor:
                mode === 'dark' ? 'bluishGrey.nine' : content?.highlight ? 'primary.main' : 'bluishGrey.four',
              my: 3,
            }}
          />
          {content.list.title && (
            <Typography
              variant='body3Bold'
              component='div'
              sx={{
                color: mode === 'dark' ? 'bluishGrey.two' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
                mb: 1.5,
              }}
            >
              {Parser(content.list.title)}
            </Typography>
          )}
          {content.list.description && (
            <Typography
              variant='body2'
              component='div'
              sx={{
                color: mode === 'dark' ? 'bluishGrey.seven' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
                mb: 1,
              }}
            >
              {Parser(content.list.description)}
            </Typography>
          )}

          <List
            dense
            disablePadding
            sx={{
              color: mode === 'dark' ? 'bluishGrey.two' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
              mb: 'auto',
            }}
          >
            {content?.list?.items.map((item, i) => (
              <ListItem key={i} sx={{ py: 0.25 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: 'primary.main', fontSize: 25, mr: 1 }} />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
          {content?.badge && (
            <Typography
              variant='body3'
              sx={{
                color: mode === 'dark' ? 'bluishGrey.seven' : content?.highlight ? 'bluishGrey.one' : 'bluishGrey.ten',
                display: 'flex',
                alignItems: 'center',
                mt: 2,
              }}
            >
              <SvgIcon inheritViewBox component={content.badge.icon} sx={{ mr: 1 }} />
              <span>Stand out with a </span>
              <Typography variant='body3Bold' sx={{ ml: 0.5 }}>
                {content.badge.name}
              </Typography>
            </Typography>
          )}
        </>
      )}
      {content.footer && (
        <Card
          square
          sx={{
            display: 'flex',
            m: -3,
            mt: 3,
            p: 1,
            backgroundColor: content?.highlight ? 'silkyGreen.light' : 'bluishGrey.three',
            color: content?.highlight ? 'silkyGreen.main' : 'bluishGrey.ten',
            alignItems: 'center',
          }}
        >
          <CardMedia
            elevation={0}
            component='img'
            sx={{ width: 60, height: 48, cursor: 'pointer' }}
            image={content.footer.thumb}
            alt={content.footer.button}
            onClick={handleClickOpen}
          />
          <CardContent sx={{ m: 0, p: '8px !important' }}>
            <Typography variant='body3' component='p'>
              {content.footer.text}
            </Typography>
            <Link
              size='small'
              variant='button'
              component='button'
              onClick={handleClickOpen}
              color={content?.highlight ? 'silkyGreen.main' : 'bluishGrey.ten'}
            >
              {content.footer.button}
            </Link>
          </CardContent>
        </Card>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'lg'}
        PaperProps={{ square: true }}
        sx={{
          '& .MuiDialogContent-root': {
            p: 3,
          },
        }}
      >
        <DialogTitle variant='h4'>
          {content?.modal?.title}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <video class='ratio ratio-16x9' controls>
            <source src={content?.modal?.video} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
      </Dialog>
    </Paper>
  )
}
export default Plan

import background1 from '../assets/images/cta/background1.jpg'
import background2 from '../assets/images/cta/background2.jpg'
import visa from '../assets/images/payment/visa.png'
import mastercard from '../assets/images/payment/mastercard.png'
import americanExpress from '../assets/images/payment/american-express.png'
import paypal from '../assets/images/payment/paypal.png'
import alipay from '../assets/images/payment/alipay.png'

export const pricingContent = {
  plans: {
    mode: 'light',
    title: 'Start Flexible Plans',
    subhead: 'Pricing',
    description: 'For our members in South Asia',
  },
  pricingTable: {
    columns: ['', 'Starter', 'Growth', 'Premium', 'Enterprise'],
    tables: [
      {
        column: 'Explore The Network',
        rows: [
          {
            name: 'Search for companies',
            description:
              'Easily connect with new or exisiting contacts in the network. Search by name or through specific filters.',
            plans: [true, true, true, true],
          },
          {
            name: 'See company profiles',
            description: 'Visit company profiles and see company info of all members in the network.',
            plans: ['Limited', true, true, true],
          },
          {
            name: 'Search for products',
            description: 'Discover products that you are interested in using industry specific filters.',
            plans: [true, true, true, true],
          },
        ],
      },
      {
        column: 'Connect & Collaborate',
        rows: [
          {
            name: 'Send connection requests',
            description: 'Send connection requests to companies to start conversations with the right contact person.',
            plans: [false, '30/month', '45/month', '60/month'],
          },
          {
            name: 'Create requests for quotations (RFQ)',
            description:
              'Find the right partner for your sourcing project. Send your proposal to relevant companies in the network, review the responses and negotiate prices.',
            plans: [true, true, true, true],
          },
          {
            name: 'Reply to requests for quotations (RFQ)',
            description:
              'Offer your services and negotiate prices with potential clients looking for services or products in your category.',
            plans: [false, true, true, true],
          },
          {
            name: 'Bookmark leads and clients',
            description:
              'Save companies you know or are in negotiation with to your bookmarks. Create organised lists and easily access your contacts.',
            plans: [true, true, true, true],
          },
          {
            name: 'Get network insights',
            description:
              'See who viewed your profile & products as well as search & demand activity across the network.',
            plans: ['Limited', true, true, true],
          },
        ],
      },
      {
        column: 'Market your business',
        rows: [
          {
            name: 'Create company profile',
            description:
              'Showcase your capabilities and services and engange with the right business partners by creating a company profile.',
            plans: ['Excl. promotional videos', true, true, true],
          },
          {
            name: 'Show verified compliance certificates',
            description:
              'Through our partnerships and APIs with certification bodies your certificates will be marked with the "Verified by Foursource" label to increase trust and credibility. Free users cannot see the status of certificates.',
            plans: [true, true, true, true],
          },
          {
            name: 'Professional membership badge',
            description: 'Mark your profile with a professional membership badge to stand out in the network.',
            plans: [false, false, 'Premium badge', 'Enterprise badge'],
          },
          {
            name: 'Google indexed profile',
            description:
              'Your company profile is SEO optimized on Google search to drive more traffic to your business.',
            plans: [false, true, true, true],
          },
          {
            name: 'Foursource search ranking ',
            description: 'Increase your companies visibility and profile traffic by ranking higher in search results.',
            plans: [false, false, 'High', 'Best'],
          },
          {
            name: 'Product promotion ',
            description: 'Promote and highlight your products and rank at the top of the product search.',
            plans: [false, '10/year', '50/year', '100/year'],
          },
          {
            name: 'Company advertisement in Foursource Newsletter',
            description: 'Feature your company in the global FS newsletter to members.',
            plans: [false, false, false, '2'],
          },
          {
            name: 'Performance reporting & dashboard ',
            description: 'See relevant network insights, profile analytics and monitor your performance.',
            plans: [false, true, true, true],
          },
        ],
      },
      {
        column: 'Showroom solutions',
        rows: [
          {
            name: 'Showrooms',
            description: 'Build your showroom and create collections of your product developments & services.',
            plans: ['1', '2', '4', 'Unlimited'],
          },
          {
            name: 'Product posts per showroom',
            description: 'Add posts to your showrooms and to present your prodcuts & developments.',
            plans: ['Up to 10', 'Up to 20', 'Up to 50', 'Unlimited'],
          },
          {
            name: 'Manage showroom privacy',
            description: 'Choose who can see and visit your shworoom collections.',
            plans: [false, true, true, true],
          },
        ],
      },
      {
        column: 'Services & support',
        rows: [
          {
            name: 'Company profile check',
            description: 'Get your company account reviewed to increase visibility and profile traffic.',
            plans: [false, false, true, true],
          },
          {
            name: 'Enterprise support agent',
            description:
              'A dedicated account manager will support you with all your enquires about your Foursource membership.',
            plans: [false, false, false, true],
          },
        ],
      },
    ],
    pricingRow: [
      { name: 'Annual Payment' },
      {
        name: 'Starter',
        price: '200€',
        button: true,
      },
      {
        name: 'Growth',
        price: '999€',
        button: true,
      },
      {
        name: 'Premium',
        price: '1499€',
        button: true,
      },
      {
        name: 'Enterprise',
        price: '1999€',
        button: true,
      },
    ],
  },
  paymentMethods: {
    subhead: 'Payment Methods',
    logos: [
      {
        src: visa,
        alt: 'Visa',
        height: 21,
      },
      {
        src: mastercard,
        alt: 'Mastercard',
        height: 32,
      },
      {
        src: americanExpress,
        alt: 'American Express',
        height: 38,
      },
      {
        src: paypal,
        alt: 'Paypal',
        height: 38,
      },
      {
        src: alipay,
        alt: 'Alipal',
        height: 21,
      },
    ],
    alert:
      "Secure payment with a debit card, credit card, PayPal, or Alipay. For bank transfers and transfers in Turkish lira please contact <a target='_blank' href='mailto:payments@foursource.com'>payments@foursource.com</a>.",
  },
  cta1: {
    title: 'Join Now And Get Access Today',
    subhead: 'Try For Free',
    bg: {
      image: background1,
      position: 'left 0',
    },
    buttons: [
      {
        text: 'Sign up',
        href: 'SIGNUP',
      },
    ],
  },
  cta2: {
    title: 'Not sure which plan to choose?',
    subhead: 'Get In Touch',
    bg: {
      image: background2,
      position: 'left 0',
    },
    buttons: [
      {
        text: 'Book a demo',
        href: 'https://demodesk.com/select/foursource-group-gmbh/choose-business-type',
        variant: 'outlined',
        color: 'white',
        target: '_blank',
        rel: 'noreferrer',
      },
    ],
  },
}

// Icons
import EnterpriseIcon from '../assets/images/svg/enterprise.inline.svg'
import GrowthIcon from '../assets/images/svg/growth-plant.inline.svg'
import GiftIcon from '../assets/images/svg/gift.inline.svg'
import GraphIcon from '../assets/images/svg/graph.inline.svg'
import SuitcaseIcon from '../assets/images/svg/suitcase.inline.svg'
import PremiumIcon from '../assets/images/svg/premium.inline.svg'
import SearchIcon from '../assets/images/svg/search.inline.svg'
// import TagIcon from '../assets/images/svg/tag.inline.svg'
import SparklingIcon from '../assets/images/svg/sparkling.inline.svg'
import StarterIcon from '../assets/images/svg/starter.inline.svg'
import ThumbPlanVideo from '../assets/images/plan/thumb-plan-video.png'

const videos = {
  starter:
    'https://s3.eu-central-1.amazonaws.com/static-prod-fra.foursource.com/public/videos/plansStarterFinalWebsite.mp4',
  growth:
    'https://s3.eu-central-1.amazonaws.com/static-prod-fra.foursource.com/public/videos/plansGrowthFinalWebsite.mp4',
  premium:
    'https://s3.eu-central-1.amazonaws.com/static-prod-fra.foursource.com/public/videos/plansPremiumFinalWebsite.mp4',
  enterprise:
    'https://s3.eu-central-1.amazonaws.com/static-prod-fra.foursource.com/public/videos/plansEnterpriseFinalWebsite.mp4',
}

export const plansContent = {
  mode: 'light',
  title: 'Start Flexible Plans',
  subhead: 'Pricing',
  description: 'For our members in South Asia',
  group: 'all',
  plans: {
    free: {
      title: 'Free',
      icon: GiftIcon,
      description:
        "Access the world's largest B2B market network for apparel and textile sourcing. No credit card required.",
      price: '0',
      list: {
        title: 'Your main benefits:',
        items: [
          'Find suppliers with limited filters',
          'Restricted visibility to supplier profiles',
          'Limited access to digital supplier showrooms',
          'Manage your connections and bookmarks',
          'Create requests for quotations (RFQ)',
        ],
      },
    },
    explorer: {
      highlight: true,
      titlebar: { icon: SparklingIcon, text: '<b>Recommended for Brands & Private Labels</b>' },
      title: 'Explorer',
      icon: SearchIcon,
      description:
        'Tailored for brands & private labels that want to find new suppliers and streamline their sourcing process.',
      price: 200,
      list: {
        title: 'Your main benefits:',
        items: [
          'Find suppliers using all filters',
          'Unlimited access to all supplier profiles',
          'Browse thousands of digital supplier showrooms',
          'Manage your supplier relationships with labels',
          'Track validity of compliance certificates',
          'Create requests for quotations (RFQ)',
          'Compare quotes to get the best price',
          'Send up to 20 connection requests per month',
          'Add colleagues to your company profile',
          'Personal onboarding and support',
        ],
      },
    },
    business: {
      title: 'Business',
      icon: SuitcaseIcon,
      description: 'Your company requires more? Get a quote tailored to the sourcing needs of your organization.',
      price: 'On request',
      list: {
        title: 'Your main benefits:',
        items: [
          'Find suppliers using all filters',
          'Unlimited access to all supplier profiles',
          'Browse thousands of digital suppliers showrooms',
          'Manage your supplier relationships with labels ',
          'Track validity of compliance certificates',
          'Create requests for quotation (RFQ)',
          'Compare quotes to get the best price',
          'Send up to 200 connection requests per month ',
          'Add unlimited users to your company account ',
          'Personal onboarding and support ',
          'Tailored sourcing assistance service according to your project and needs',
        ],
      },
      button: {
        text: 'Contact Us',
        href: 'mailto:payments@foursource.com?subject=Pricing - Business Plan',
        target: '_blank',
      },
    },
    starter: {
      title: 'Starter',
      icon: StarterIcon,
      description: 'The entry ticket for your company to be visible in the largest sourcing network.',
      price: 200,
      list: {
        title: 'Your main benefits:',
        items: [
          'Present your company with a digital profile',
          'Have your company appear in the search results ',
          'Have your certificates shown as validated',
          'Reply to connection requests and messages without limits ',
          'Set up 1 digital showroom',
          'Present up to 10 products in your showroom',
          'Search the network using all filters ',
          'Create requests for quotations (RFQs) for your sourcing needs',
          'Manage your connections and bookmarks',
        ],
      },
      footer: {
        thumb: ThumbPlanVideo,
        text: 'Want to know more?',
        button: 'Watch Starter plan video',
      },
      modal: {
        title: 'Starter video',
        video: videos.starter,
      },
    },
    growth: {
      title: 'Growth',
      icon: GrowthIcon,
      description: 'Get your business growing by offering your products and services, and gaining relevant leads.',
      price: 999,
      list: {
        title: 'Your main benefits:',
        description: 'All from <b>Starter</b> with following modifications:',
        items: [
          'Set up 2 digital showrooms',
          'Add up to 20 products to each showrooms',
          'Manage the visibility of your showrooms',
          'Promote 10 products per year to rank on top',
          'Reply to requests for quotations (RFQs)',
          'Send 30 connection requests per month ',
          'View and react to business opportunities ',
          'Know who viewed your profile and products ',
          'Have a personalized KPI dashboard',
          'Have a SEO optimized website',
        ],
      },
      footer: {
        thumb: ThumbPlanVideo,
        text: 'Want to know more?',
        button: 'Watch Growth plan video',
      },
      modal: {
        title: 'Growth video',
        video: videos.growth,
      },
    },
    premium: {
      highlight: true,
      titlebar: { icon: SparklingIcon, text: '<b>Recommended for Factories & Production Facilities</b>' },
      title: 'Premium',
      icon: SuitcaseIcon,
      description: 'Suitable for companies offering a larger range of products and services.',
      price: 1499,
      list: {
        title: 'Your main benefits:',
        description: 'All from <b>Growth</b> with following modifications:',
        items: [
          'Set up 4 digital showrooms',
          'Add up to 50 products per showroom',
          'Promote 50 products per year to rank on top',
          'Send 45 connection requests per month',
          'Higher ranking of your company in search results',
          'Premium badge for more visibility and trust ',
        ],
      },
      footer: {
        thumb: ThumbPlanVideo,
        text: 'Want to know more?',
        button: 'Watch Premium plan video',
      },
      modal: {
        title: 'Premium video',
        video: videos.premium,
      },
      badge: {
        name: 'Premium badge',
        icon: PremiumIcon,
      },
    },
    enterprise: {
      title: 'Enterprise',
      icon: GraphIcon,
      description: 'Dedicated plan for larger organizations with multiple product categories and highest expectations.',
      price: 1999,
      list: {
        title: 'Your main benefits:',
        description: 'All from <b>Premium</b> with following modifications:',
        items: [
          'Set up unlimited digital showrooms',
          'Add unlimited products to your showrooms',
          'Promote 100 products per year to list on top',
          'Send 60 connection requests per month',
          'Highest ranking of your company in search results',
          'Enterprise badge for more visibility and trust ',
          'Get featured 2 times in our newsletters',
          'Have your profile reviewed by an expert',
          'Receive personal support by a dedicated agent',
        ],
      },
      footer: {
        thumb: ThumbPlanVideo,
        text: 'Want to know more?',
        button: 'Watch Enterprise plan video',
      },
      modal: {
        title: 'Enterprise video',
        video: videos.enterprise,
      },
      badge: {
        name: 'Enterprise badge',
        icon: EnterpriseIcon,
      },
    },
  },
}

// Base
import * as React from 'react'
import deepmerge from 'deepmerge'

// Components
import { Box, Container, Toolbar, Typography } from '@mui/material'
import PlansSuppliers from './PlansSuppliers'

// Content
import { plansContent } from '../../content/plans-south-asia'

// Styles
import * as S from './Plans.styles'

const PlansSouthAsia = ({ content, value, handleChange }) => {
  let props = { ...plansContent }
  content &&
    (props = deepmerge(plansContent, content, {
      arrayMerge: (destination, source) => {
        return [...destination, ...source]
      },
    }))

  return (
    <Box sx={{ my: { xs: 6, md: 8 } }}>
      <Toolbar />
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }}>
        <Typography variant='subhead'>{props?.subhead}</Typography>
        <Typography variant='h2' sx={{ color: props?.mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten', mb: 5 }}>
          {props?.title}
        </Typography>
        <Typography
          variant='body1'
          sx={{
            color: props?.mode === 'dark' ? 'bluishGrey.two' : 'bluishGrey.ten',
            mt: -3,
            mb: 5,
          }}
        >
          {props?.description}
        </Typography>
        <PlansSuppliers content={props} />
      </Container>
    </Box>
  )
}

export default PlansSouthAsia

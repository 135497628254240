// Base
import * as React from 'react'

// Components
import Plan from './Plan'
import Slider from '../Slider'

const PlansSuppliers = ({ content }) => {
  return (
    <Slider
      content={{
        className: 'swiper-plans',
        pagination: { clickable: true },
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: false,
      }}
    >
      <Plan content={content?.plans?.starter} mode={content?.mode} />
      <Plan content={content?.plans?.growth} mode={content?.mode} />
      <Plan content={content?.plans?.premium} mode={content?.mode} />
      <Plan content={content?.plans?.enterprise} mode={content?.mode} />
    </Slider>
  )
}

export default PlansSuppliers
